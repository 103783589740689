.page_title{
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1.875rem;
}
.content_wrapper{
    display: flex;
    min-height: calc(100vh - 16.875rem);
    margin: 2.5rem 0 3.125rem;
}
.img_wrapper{
    width: 50vw;
    font-size: 0; /* fix unexpected spaces */
}
.img{
    width: 100%;
}
.img_cover{
    display: block;
}
.img_cover_mobile{
    display: none;
}
.info_wrapper{
    width: 40vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    margin-left: 3vw;
}
.info_wrapper a{
    text-decoration: none;
}
.title{
    font-size: 2rem;
    font-weight: 500;
}
.subtitle{
    font-size: 1.25rem;
    color: #969696;
    margin: 0.375rem 0 1.5rem;
}
.description{
    font-size: 1.25rem;
}
.tools_wrapper{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.tools_box{
    padding: 0.375rem 0.625rem;
    border-radius: 0.375rem;
    border: 1px solid #C4C4C4;
    margin: 0.375rem 0.625rem 0.375rem 0;
    font-size: 0.875rem;
}
.links_wrapper{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1.25rem;
}
.view_btn{
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background-color: #C0A795;
    border: 1px solid #C0A795;
    border-radius: 0.375rem;
    margin: 0 1rem 1rem 0;
    padding: 0.5rem 1.5rem;
}
.view_btn:hover{
    color: #C0A795;
    background-color: #fff;
    cursor: pointer;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
.date_wrapper{
    font-size: 1rem;
    color: #969696;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.date_icon{
    width: 1.2rem;
    margin-right: 0.5rem;
}
.note_wrapper{
    color: #969696;
    font-size: 1rem;
}
.note_title{
    font-weight: 500;
}

/* Mobile */
@media (max-width: 600px) {
    .page_title{
        font-size: 1.5rem;
    }
    .content_wrapper{
        flex-direction: column-reverse;
        margin: 1rem 0 2rem;
    }
    .img_wrapper{
        width: calc(100vw - 2rem);
    }
    .img_cover{
        display: none;
    }
    .img_cover_mobile{
        display: block;
        margin-bottom: 1.25rem;
    }
    .info_wrapper{
        width: calc(100vw - 2rem);
        margin-left: 0;
        margin-bottom: 2rem;
    }
    .title{
        font-size: 1.75rem;
    }
    .subtitle{
        font-size: 1rem;
    }
    .description{
        font-size: 1rem;
    }
}