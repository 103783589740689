@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General */
*,
::before,
::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  width: 100vw;
  height: 100vh;
  font-size: 16px;
}
body {
  background-color: #F4F4F2;
  color: #484848;
  text-align: center;
  font-family: 'Comfortaa';
}
a{
  text-decoration: none;
  color: #484848;
}
a:hover{
  color: #CB9F86;
}

/* Main */
.container{
  background-color: #F4F4F2;
  color: #484848;
  width: 100vw;
  max-width: 100%; /* for bootstrap*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.5rem 0 2.5rem;
  box-sizing: border-box;
}
.Header_header_wrapper__xoaDf{
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: rgba(244, 244, 242, 1);
    color: #484848;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 3.75rem;
    border-bottom: 1px solid #C4C4C4;
    padding: 0 3rem;
    box-sizing: border-box;
    transition: all 0.2s ease;
}
.Header_header_wrapper__xoaDf a{
    text-decoration: none;
    color: #484848;
}
.Header_header_wrapper__xoaDf.Header_hidden__2_USb{
    background-color: rgba(244, 244, 242, 0.8);
    height: 3rem;
    transition: all 0.2s ease;
}
.Header_header_logo__3ECFX{
    display: flex;
    align-items: center;
}
.Header_header_menu__1Xf6P{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}
.Header_logo__3EvUy{
    width: 3rem;
    margin-right: 0.75rem;
}
.Header_menu_item__1fVEf{
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 1rem;
}
.Header_menu_item__1fVEf:hover{
    color: #CB9F86;
    transition: all 0.2s ease-out;
    cursor: pointer;
}
.Header_btn_menu__3O8Zg{
    display: none !important;
}

/* Mobile */
@media (max-width: 600px) {
    .Header_header_wrapper__xoaDf{
        padding: 0 2rem;
    }
    .Header_header_menu__1Xf6P{
        position: absolute;
        top: 3.5rem;
        height: calc(100vh - 3.5rem);
        transform: translateX(+100%);
        transition: all 0.5s ease;
        width: 100vw;
        padding: 3rem 1.5rem;
        margin-left: -2rem;
        flex-direction: column;
        align-items: flex-start;
        background-color: rgba(211, 197, 189, 0.96);
    }
    .Header_header_menu__1Xf6P.Header_active__11YnJ{
        transform: translateX(0);
        transition: all 0.5s ease;
    }
    .Header_header_wrapper__xoaDf.Header_hidden__2_USb .Header_header_menu__1Xf6P{
        top: 3rem;
        height: calc(100vh - 3rem);
    }
    .Header_menu_item__1fVEf{
        font-size: 2.25rem;
        padding: 1rem 0;
    }
    .Header_btn_menu__3O8Zg{
        display: block !important;
        width: 2rem;
        margin-left: auto;
    }
}
.Footer_footer_wrapper__24qM8{
    background-color: #F4F4F2;
    color: #484848;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 7.5rem;
    border-top: 1px solid #C4C4C4;
    box-sizing: border-box;
    margin-top: auto;
    font-size: 1rem;
}
.Footer_footer_icon_wrapper__256hH{
    display: flex;
    margin-bottom: 0.75rem;
}
.Footer_footer_icon_box__-FuPs{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #484848;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 0.25rem;
}
.Footer_footer_icon__1KTYO{
    width: 2.25rem;
}
.Portfolio_container__YR56b{
    min-height: 50vh;
    padding: 2.5rem 0 0.625rem;
    box-sizing: border-box;
}
.Portfolio_list_wrapper__8jc5g a{
    text-decoration: none;
    color: #484848;
}
.Portfolio_list_wrapper__8jc5g{
    display: flex;
    width: 60vw;
    flex-wrap: wrap;
    justify-content: space-between;
}
.Portfolio_item_box__16J9U{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 48%;
    aspect-ratio: 4 / 3;
    cursor: pointer;
    margin-bottom: 2.25rem;
    border: 1.5px solid #C4C4C4;
    padding: 1rem 0 1.25rem;
    box-sizing: border-box;
    border-radius: 0.375rem;
}
.Portfolio_item_box__16J9U:hover{
    border: 1.5px solid #CB9F86;
    color: #CB9F86;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
    background-color: #fff;
}
.Portfolio_item_img_box__319xN{
    width: 90%;
    aspect-ratio: 16 / 9;
}
.Portfolio_item_img__2QFyu{
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
}
.Portfolio_item_title__3wGR8{
    font-size: 1.5rem;
    margin-top: 1rem;
}
.Portfolio_item_subtitle__1OEPM{
    font-size: 1rem;
    display: none;
}
.Portfolio_item_box__16J9U.Portfolio_featured__2JpDZ{
    width: 20rem;
    margin: 0.625rem 1rem;
}
.Portfolio_item_box__16J9U:hover .Portfolio_item_img_box__319xN{
    display: none;
}
.Portfolio_item_box__16J9U:hover .Portfolio_item_subtitle__1OEPM{
    display: block;
    font-size: 1.2rem;
    margin-top: 1rem;
    padding: 0 1rem;
}

/* Mobile */
@media (max-width: 600px) {
    .Portfolio_list_wrapper__8jc5g{
        width: 85vw;
    }
    .Portfolio_item_box__16J9U.Portfolio_featured__2JpDZ{
        width: 60vw;
        margin: 0.625rem;
    }
    .Portfolio_item_title__3wGR8{
        font-size: 1rem;
    }
    .Portfolio_item_subtitle__1OEPM{
        font-size: 0.8rem;
    }
}
.BackToTop_btn__6d14k{
    position: fixed;
    z-index: 999;
    right: 3.75rem;
    bottom: 3.125rem;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 1rem;
    border: 1.5px solid #CB9F86;
    color: #CB9F86;
}
.BackToTop_btn__6d14k:hover{
    cursor: pointer;
    background-color: #fff;
}

/* Mobile */
@media (max-width: 600px) {
    .BackToTop_btn__6d14k{
        right: 1rem;
        bottom: 2rem;
    }
}
.Loading_container__1aMyo{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Comfortaa';
    background-color: rgba(244, 244, 242, 0.8);
    color: #484848;
}
.Loading_animation__1iGhr{
    width: 18.75rem;
    height: auto;
}
.Loading_text__2sRFN{
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: -0.625rem;
}
.YoutubeEmbed_video__1nBMy{
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
  
.YoutubeEmbed_video__1nBMy iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
.Breadcrumb_breadcrumb_wrapper__2j9-U{
    color: rgba(72, 72, 72, 0.6);
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0.5rem 0;
}
.Breadcrumb_breadcrumb_wrapper__2j9-U a{
    text-decoration: none;
    color: rgba(72, 72, 72, 0.6);
}
.Breadcrumb_breadcrumb_wrapper__2j9-U a:hover{
    color: #CB9F86;
    cursor: pointer;
}
.Breadcrumb_item_box__3pUhO{
    display: flex;
}
.Breadcrumb_breadcrumb_item__3NHwX{
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0.25rem;
}
.Model_container__3WKlx{
    min-height: 50vh;
    padding: 2.5rem 0 0.625rem;
    box-sizing: border-box;
}
.Model_list_wrapper__1Z8az a{
    text-decoration: none;
    color: #484848;
}
.Model_list_wrapper__1Z8az{
    display: flex;
    width: 80vw;
    flex-wrap: wrap;
    justify-content: space-between;
}
.Model_item_box__3C-zx{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 24vw;
    height: 36vh;
    cursor: pointer;
    margin-bottom: 2.25rem;
    border: 1.5px solid #C4C4C4;
    padding: 1rem 0 1.25rem;
    box-sizing: border-box;
    border-radius: 0.375rem;
}
.Model_item_box__3C-zx:hover{
    border: 1.5px solid #CB9F86;
    color: #CB9F86;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
.Model_item_text__Q6iCk{
    font-size: 1.5rem;
    margin-top: 1rem;
}

/* Mobile */
@media (max-width: 600px) {
    .Model_list_wrapper__1Z8az{
        width: 85vw;
    }
    .Model_item_box__3C-zx{
        width: 40vw;
        height: 65vw;
        margin-bottom: 5vw;
    }
    .Model_item_text__Q6iCk{
        font-size: 1rem;
        margin-top: 0.5rem;
    }
}
/* Animation */
@keyframes HomePage_fadeIn__3vsrp {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

/* Main */
.HomePage_block_wrapper__Zp6HM{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    padding: 3.5rem 3.5rem 0 8rem;
    box-sizing: border-box;
    z-index: 1;
}
.HomePage_block_wrapper__Zp6HM a{
    text-decoration: none;
    color: #484848;
}
.HomePage_page_title__3MdSN{
    font-size: 3rem;
    font-weight: 500;
    position: relative;
    min-width: 50%;
    text-align: left;
    margin-top: 2rem;
}
.HomePage_page_subtitle__786Y1{
    font-size: 2.5rem;
    position: relative;
    min-width: 50%;
    text-align: left;
    margin-left: 9rem;
    margin-top: 0.5rem;
}

/* Logo */
.HomePage_block_wrapper_logo__30XJ9{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    z-index: 1;
}
.HomePage_logo_name__EH6nt{
    position: relative;
    font-size: 6rem;
    margin-top: calc((100vh - 10.625rem)/2);
    animation: HomePage_fadeIn__3vsrp 1s ease-in;
}
.HomePage_logo_sparkle__2Aidb{
    fill: #CB9F86;
    position: absolute;
    top: -4rem;
    animation: HomePage_fadeIn__3vsrp 1s ease-in-out infinite alternate;
}
.HomePage_arrow01__gBpvq{
    position: absolute;
    transform: rotate(115deg);
    left: 40vw;
    bottom: 1rem;
    stroke: #484848;;
    stroke-width: 4px;
    animation: HomePage_fadeIn__3vsrp 1s ease-in;
}

/* Web */
.HomePage_underline__Otnuc{
    stroke: #CB9F86;
    stroke-width: 3px;
    position: absolute;
    width: 12rem;
    left: -1rem;
    top: 3.2rem;
}
.HomePage_web_content_wrapper__3uGwT{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: -10rem;
}
.HomePage_web_content__1fO5d{}
.HomePage_web_description__uUNBA{
    font-size: 1.2rem;
    width: 35rem;
    text-align: left;
    margin-top: 10rem;
    margin-bottom: 4rem;
}
.HomePage_web_description_title__iFHjm{
    font-size: 1.5rem;
    font-weight: 500;
    color: #CB9F86;
    margin-top: 1.5rem;
}
.HomePage_web_btn__3RMem{}
.HomePage_icon_group__XePdH{
    display: flex;
    flex-wrap: wrap;
    max-width: 40vw;
    opacity: 0.4;
}
.HomePage_icon__gSlUE{
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.8rem;
}
.HomePage_web_box_wrapper__1bfYe{
    position: relative;
    min-width: 31rem;
    min-height: 21rem;
    margin-top: 2rem;
}
.HomePage_web_box_back__7ZR_d{
    position: absolute;
    width: 26rem;
    height: 18rem;
    border-radius: 10px;
    border: 2px solid #C4C4C4;
    background-color: #F4F4F2;
    left: 5rem;
    bottom: 3rem;
    z-index: 9;
}
.HomePage_web_box_front__2xoKX{
    position: absolute;
    width: 26rem;
    height: 18rem;
    border-radius: 10px;
    border: 2px solid #C4C4C4;
    background-color: #F4F4F2;
    left: -3rem;
    bottom: 0;
    z-index: 10;
}
.HomePage_web_box_back__7ZR_d:hover{
    z-index: 99;
}
.HomePage_web_img_box__DH6Qx{
    width: 95%;
    aspect-ratio: 16 / 9;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
}
.HomePage_web_img__agc3o{
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
}

/* App */
.HomePage_app_content_wrapper__2IAJp{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.HomePage_app_content__1NKNq{}
.HomePage_icon_group_app__29NFg{
    display: flex;
    flex-wrap: wrap;
    max-width: 20rem;
    opacity: 0.4;
}
.HomePage_app_box__1NwAv{
    position: relative;
    width: 14rem;
    height: 29.3rem;
    transform: scale(1.2);
    margin: 0 3rem;
}
.HomePage_app_img_box__2ERNw{
    position: relative;
    width: 14rem;
    height: 29.3rem;
    border: 3px solid #C4C4C4;
    border-radius: 40px;
    overflow: hidden;
}
.HomePage_app_img_back__2FxKs{
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
    display: none;
}
.HomePage_app_img_front__3of9a{
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
    display: block;
}
.HomePage_app_mask__3zd1m{
    position: absolute;
    width: 14rem;
    height: auto;
    stroke: #484848;
    top: 0;
    left: 0;
}
.HomePage_app_box__1NwAv:hover .HomePage_app_img_front__3of9a{
    display: none;
}
.HomePage_app_box__1NwAv:hover .HomePage_app_img_back__2FxKs{
    display: block;
}
.HomePage_app_description__1dQe_{
    font-size: 1.2rem;
    max-width: 25rem;
    padding: 0 1rem;
    margin-bottom: 5rem;
}
.HomePage_app_btn__27Xj8{}

/* Design */
.HomePage_design_content_wrapper__2Fh7Z{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5rem;
    margin-top: 3rem;
}
.HomePage_design_content__G6JyO{}
.HomePage_design_box_wrapper__2cy1z{
    position: relative;
    display: flex;
    width: 22rem;
    flex-direction: column;
    align-items: center;
}
.HomePage_design_img_box__15lBg{
    position: absolute;
    width: 20rem;
    height: 20rem;
    top: 1rem;
}
.HomePage_design_img__1Mp2y{
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
    display: none;
}
.HomePage_design_box__3mVQV{
    width: 22rem;
    height: 22rem;
    stroke: #484848;
    fill: #E5E5E5;
    z-index: 10;
}
.HomePage_design_box_tag__6B_TC{
    width: 7rem;
    height: 1.8rem;
    line-height: 1.8rem;
    color: #fff;
    background-color: #484848;
    border-radius: 5px;
}
.HomePage_design_select__1CE2N{
    fill: #484848;
    stroke: #fff;
    transform: rotate(-35deg);
    position: absolute;
    width: 3.5rem;
    top: 12rem;
    right: -3rem;
    transition: all 1s ease;
    z-index: 11;
}
.HomePage_design_box_wrapper__2cy1z:hover .HomePage_design_img__1Mp2y{
    display: block;
    animation: HomePage_fadeIn__3vsrp 0.5s ease-in-out;
}
.HomePage_design_box_wrapper__2cy1z:hover .HomePage_design_box__3mVQV{
    fill: none;
}
.HomePage_design_box_wrapper__2cy1z:hover .HomePage_design_select__1CE2N{
    right: 3rem;
}
.HomePage_icon_group_design__3JZy4{
    display: flex;
    flex-wrap: wrap;
    max-width: 40vw;
    opacity: 0.4;
}
.HomePage_design_pen_wrapper__3R_cJ{
    position: relative;
    margin-top: 4rem;
    margin-bottom: 6rem;
}
.HomePage_design_pen_path__OULRc{
    stroke: #CB9F86;
    stroke-width: 3px;
}
.HomePage_design_pen__2rEJm{
    stroke: #484848;
    fill: #484848;
    position: absolute;
    top: -5px;
}
.HomePage_design_unit_wrapper__25kJ2{}
.HomePage_design_unit__vKCEk{
    font-size: 2rem;
    text-align: right;
}
.HomePage_design_btn__28bYz{}

/* Others */
.HomePage_others_content_wrapper__1Lhq4{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
}
.HomePage_others_content__UQGQX{}
.HomePage_icon_group_others__27pyv{
    display: flex;
    flex-wrap: wrap;
    max-width: 40vw;
    opacity: 0.4;
}
.HomePage_axis__17LOc{
    fill: #CB9F86;
    width: 8rem;
    margin-top: -4rem;
    margin-right: 4rem;
}
.HomePage_model_wrapper__3jFzL{
    width: 48rem;
    height: 24rem;
    border: 2px solid #C4C4C4;
    border-radius: 15px;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.HomePage_others_btn__1QfwQ{}

/* Conclusion */
.HomePage_conclusion_content_wrapper__2tg7s{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5rem;
}
.HomePage_conclusion_content__32cir{
    width: 25rem;
}
.HomePage_category_wrapper__1jhjs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    opacity: 0.4;
}
.HomePage_category__32uIW{
    margin: 1rem;
}
.HomePage_category_icon__3c9rs{
    width: 5rem;
    height: 5rem;
}
.HomePage_arrow_double__Gc3Di{
    stroke: #CB9F86;
    stroke-width: 5px;
    position: relative;
    width: 10rem;
    margin: 0 2rem;
    transition: all 1s ease;
}
.HomePage_arrow_double__Gc3Di:hover{
    transform: rotate(360deg);
}
.HomePage_conclusion_icon_box__P4IJE{
    position: relative;
    margin-bottom: 3rem;
}
.HomePage_conclusion_icon__3VI3o{
    width: 4rem;
    height: 4rem;
}

/* Dot */
.HomePage_dot_wrapper__3ODCg{
    position: fixed;
    left: 2.5rem;
    top: calc(100vh / 2 - 9.625rem);
    z-index: 999;
}
.HomePage_dot_line__2-Tfh{
    position: absolute;
    width: 1px;
    height: calc(100% - (12px + 1.5rem - 1.5px) * 2);
    background-color: #484848;
    top: calc(12px + 1.5rem - 1.5px);
    left: calc(12px / 2 - 0.5px);
    z-index: 1;
}
.HomePage_dot_box__1Ku3K{
    display: flex;
    align-items: center;
}
.HomePage_dot__21L5G{
    width: 12px;
    height: 12px;
    border: 1.5px solid #484848;
    margin: 1.5rem 0;
    transform: rotate(45deg);
    transition: all 0.5s ease;
    z-index: 999;
}
.HomePage_dot__21L5G:hover{
    cursor: pointer;
    border: 4px solid #CB9F86;
}
.HomePage_dot__21L5G:hover + .HomePage_dot_name__2bub_{
    display: block;
}
.HomePage_dot_name__2bub_{
    display: none;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1.2rem;
}

/* Mobile */
@media (max-width: 600px) {
    /* Main */
    .HomePage_block_wrapper__Zp6HM{
        padding: 3.5rem 1rem 0 1rem;
    }
    .HomePage_page_title__3MdSN{
        font-size: 2rem;
    }
    .HomePage_page_subtitle__786Y1{
        font-size: 1.5rem;
        margin-left: 4rem;
        margin-top: 0.3rem;
    }
    /* Logo */
    .HomePage_logo_name__EH6nt{
        font-size: 2.8rem;
    }
    .HomePage_logo_sparkle__2Aidb{
        width: 2.2rem;
    }
    .HomePage_arrow01__gBpvq{
        width: 10rem;
        left: calc(50vw - 5rem);
    }
    /* Web */
    .HomePage_underline__Otnuc{
        width: 7rem;
        top: 1.5rem;
    }
    .HomePage_web_content_wrapper__3uGwT{
        flex-direction: column;
        margin-top: 0;
    }
    .HomePage_web_content__1fO5d{
        width: 100%;
    }
    .HomePage_web_description__uUNBA{
        position: relative;
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .HomePage_icon_group__XePdH{
        position: relative;
        max-width: 100%;
        margin-top: 2.5rem;
        justify-content: center;
    }
    .HomePage_web_box_wrapper__1bfYe{
        position: relative;
        min-width: 23rem;
        min-height: 15rem;
        max-width: 23rem;
        margin-left: auto;
        margin-right: auto;
    }
    .HomePage_web_box_back__7ZR_d{
        width: 18rem;
        height: 12rem;
    }
    .HomePage_web_box_front__2xoKX{
        width: 18rem;
        height: 12rem;
        left: 0;
    }
    /* App */
    .HomePage_app_content_wrapper__2IAJp{
        flex-direction: column;
        margin-top: 1.5rem;
    }
    .HomePage_icon_group_app__29NFg{
        position: relative;
        max-width: 100%;
        justify-content: center;
    }
    .HomePage_app_box__1NwAv{
        transform: scale(0.8);
    }
    .HomePage_app_description__1dQe_{
        margin-bottom: 3rem;
    }
    /* Design */
    .HomePage_design_content_wrapper__2Fh7Z{
        flex-direction: column;
        margin-top: 2rem;
    }
    .HomePage_design_box_wrapper__2cy1z{
        transform: scale(0.8);
    }
    .HomePage_icon_group_design__3JZy4{
        position: relative;
        max-width: 100%;
        justify-content: center;
    }
    .HomePage_design_pen_wrapper__3R_cJ{
        transform: scale(0.6);
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .HomePage_design_unit_wrapper__25kJ2{
        display: none;
    }
    /* Others */
    .HomePage_others_content_wrapper__1Lhq4{
       flex-direction: column;
       align-items: center;
    }
    .HomePage_axis__17LOc{
        display: none;
    }
    .HomePage_model_wrapper__3jFzL{
        position: relative;
        width: 100%;
        margin-bottom: 3rem;
    }
    /* Conclusion */
    .HomePage_conclusion_content_wrapper__2tg7s{
        flex-direction: column;
        margin-top: 2rem;
    }
    .HomePage_conclusion_content__32cir{
        position: relative;
        width: 100%;
    }
    .HomePage_category_wrapper__1jhjs{
        padding: 0 2rem;
    }
    .HomePage_category_icon__3c9rs{
        width: 3rem;
        height: 3rem;
    }
    .HomePage_arrow_double__Gc3Di{
        margin: 3rem 0;
    }
    /* Dot */
    .HomePage_dot_wrapper__3ODCg{
        display: none;
    }
}
.PortfolioPage_page_title__3CLRS{
    font-size: 2.75rem;
    font-weight: 600;
    margin: 3.125rem 0 1.875rem;
}
.PortfolioPage_featured_section__2zAoG{
    width: 100%;
    overflow-x: auto;
}
.PortfolioPage_featured_section__2zAoG::-webkit-scrollbar {
    background: transparent;
    height: 0px;
}
.PortfolioPage_featured_wrapper__jwLIK{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 2rem 0 2.5rem;
}
.PortfolioPage_featured_title__15e3f{
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: #CB9F86;
}
.PortfolioPage_featured_title__15e3f .PortfolioPage_divider__xZB1T{
    width: 10rem;
    height: 1.5px;
    background-color: #CB9F86;
    margin: 0 1.5rem;
}
.PortfolioPage_section_divider__1DPzo{
    width: 24rem;
    height: 2px;
    background-image: linear-gradient(to right, #CB9F86 45%, rgba( 72, 72, 72, 0) 0%);
    background-position: bottom;
    background-size: 2rem 2px;
    background-repeat: repeat-x;
    margin: 2rem 0;
}
.PortfolioPage_category_wrapper__1eSWZ{
    width: 54vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}
.PortfolioPage_category__3YuYw{
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.PortfolioPage_category_btn__w_l1r{
    width: 16vw;
    height: 16vw;
    padding: 5vw;
    box-sizing: border-box;
    border: 1.5px solid #C4C4C4;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PortfolioPage_category_btn__w_l1r:hover{
    border: 1.5px solid #CB9F86;
    cursor: pointer;
}
.PortfolioPage_category_title__2QtJV{
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 1rem;
}
.PortfolioPage_null_text__2eAtn{
    font-size: 1.25rem;
    opacity: 0.6;
    margin: 1rem 0;
}

/* Mobile */
@media (max-width: 600px) {
    .PortfolioPage_featured_title__15e3f .PortfolioPage_divider__xZB1T{
        width: 3rem;
    }
    .PortfolioPage_featured_wrapper__jwLIK{
        width: calc((60vw + 1.25rem) * 3);
        padding: 0 1rem;
    }
    .PortfolioPage_section_divider__1DPzo{
        width: 16rem;
    }
    .PortfolioPage_category_wrapper__1eSWZ{
        width: 85vw;
    }
    .PortfolioPage_category_btn__w_l1r{
        width: 25vw;
        height: 25vw;
        padding: 6vw;
    }
    .PortfolioPage_category_title__2QtJV{
        font-size: 1rem;
        font-weight: 500;
        margin-top: 0.875rem;
    }
}
.PortfolioDetailPage_page_title__2A4FR{
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1.875rem;
}
.PortfolioDetailPage_content_wrapper__Yb7rW{
    display: flex;
    min-height: calc(100vh - 16.875rem);
    margin: 2.5rem 0 3.125rem;
}
.PortfolioDetailPage_img_wrapper__3xGYa{
    width: 50vw;
    font-size: 0; /* fix unexpected spaces */
}
.PortfolioDetailPage_img__2EIOZ{
    width: 100%;
}
.PortfolioDetailPage_img_cover__2kkgS{
    display: block;
}
.PortfolioDetailPage_img_cover_mobile__5xxzB{
    display: none;
}
.PortfolioDetailPage_info_wrapper__3Aupv{
    width: 40vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    margin-left: 3vw;
}
.PortfolioDetailPage_info_wrapper__3Aupv a{
    text-decoration: none;
}
.PortfolioDetailPage_title__3D2rQ{
    font-size: 2rem;
    font-weight: 500;
}
.PortfolioDetailPage_subtitle__2_pFq{
    font-size: 1.25rem;
    color: #969696;
    margin: 0.375rem 0 1.5rem;
}
.PortfolioDetailPage_description__HOnyT{
    font-size: 1.25rem;
}
.PortfolioDetailPage_tools_wrapper__HXnFS{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.PortfolioDetailPage_tools_box__raFDE{
    padding: 0.375rem 0.625rem;
    border-radius: 0.375rem;
    border: 1px solid #C4C4C4;
    margin: 0.375rem 0.625rem 0.375rem 0;
    font-size: 0.875rem;
}
.PortfolioDetailPage_links_wrapper__bDTKP{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1.25rem;
}
.PortfolioDetailPage_view_btn__3tdBR{
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background-color: #C0A795;
    border: 1px solid #C0A795;
    border-radius: 0.375rem;
    margin: 0 1rem 1rem 0;
    padding: 0.5rem 1.5rem;
}
.PortfolioDetailPage_view_btn__3tdBR:hover{
    color: #C0A795;
    background-color: #fff;
    cursor: pointer;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
.PortfolioDetailPage_date_wrapper__1-Bn_{
    font-size: 1rem;
    color: #969696;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.PortfolioDetailPage_date_icon__1pear{
    width: 1.2rem;
    margin-right: 0.5rem;
}
.PortfolioDetailPage_note_wrapper__1Vtdo{
    color: #969696;
    font-size: 1rem;
}
.PortfolioDetailPage_note_title__1rRuY{
    font-weight: 500;
}

/* Mobile */
@media (max-width: 600px) {
    .PortfolioDetailPage_page_title__2A4FR{
        font-size: 1.5rem;
    }
    .PortfolioDetailPage_content_wrapper__Yb7rW{
        flex-direction: column-reverse;
        margin: 1rem 0 2rem;
    }
    .PortfolioDetailPage_img_wrapper__3xGYa{
        width: calc(100vw - 2rem);
    }
    .PortfolioDetailPage_img_cover__2kkgS{
        display: none;
    }
    .PortfolioDetailPage_img_cover_mobile__5xxzB{
        display: block;
        margin-bottom: 1.25rem;
    }
    .PortfolioDetailPage_info_wrapper__3Aupv{
        width: calc(100vw - 2rem);
        margin-left: 0;
        margin-bottom: 2rem;
    }
    .PortfolioDetailPage_title__3D2rQ{
        font-size: 1.75rem;
    }
    .PortfolioDetailPage_subtitle__2_pFq{
        font-size: 1rem;
    }
    .PortfolioDetailPage_description__HOnyT{
        font-size: 1rem;
    }
}
.PortfolioListPage_page_title__39_HB{
    font-size: 2.75rem;
    font-weight: 600;
    margin-top: 3.125rem;
}
.PortfolioListPage_null_text__1qKXu{
    font-size: 1.25rem;
    opacity: 0.6;
    margin-top: 4rem;
}
.ModelDetailPage_page_title__qljaE{
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1.875rem;
}
.ModelDetailPage_content_wrapper__1Z5Y-{
    display: flex;
    min-height: calc(100vh - 16.875rem);
    margin: 2.5rem 0 3.125rem;
}
.ModelDetailPage_model_wrapper__37hrc{
    width: 50vw;
    height: 50vh;
}
.ModelDetailPage_model_note__Nnbir{
    margin-top: 1rem;
}
.ModelDetailPage_info_wrapper__z2dfh{
    width: 40vw;
    text-align: left;
    margin-left: 3vw;
}
.ModelDetailPage_info_wrapper__z2dfh a{
    text-decoration: none;
}
.ModelDetailPage_title__3-flL{
    font-size: 2rem;
    font-weight: 500;
}
.ModelDetailPage_subtitle__B5x0f{
    font-size: 1.25rem;
    color: #969696;
    margin: 0.375rem 0 1.5rem;
}
.ModelDetailPage_description__KDSQc{
    font-size: 1.25rem;
}
.ModelDetailPage_tools_wrapper__3x7PJ{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.ModelDetailPage_tools_box__2j5_U{
    padding: 0.375rem 0.625rem;
    border-radius: 0.375rem;
    border: 1px solid #C4C4C4;
    margin: 0.375rem 0.625rem 0.375rem 0;
    font-size: 0.875rem;
}
.ModelDetailPage_note_wrapper__HTkM4{
    color: #969696;
    font-size: 1rem;
    margin-top: 1.25rem;
}
.ModelDetailPage_note_title__2Md1_{
    font-weight: 500;
}

/* Mobile */
@media (max-width: 600px) {
    .ModelDetailPage_page_title__qljaE{
        font-size: 1.5rem;
    }
    .ModelDetailPage_content_wrapper__1Z5Y-{
        flex-direction: column;
        margin: 1rem 0 2rem;
    }
    .ModelDetailPage_model_wrapper__37hrc{
        width: calc(100vw - 2rem);
        height: calc(100vw - 2rem);
        margin-bottom: 2rem;
    }
    .ModelDetailPage_model_note__Nnbir{
        margin-top: 0.5rem;
    }
    .ModelDetailPage_info_wrapper__z2dfh{
        width: calc(100vw - 2rem);
        margin-left: 0;
    }
    .ModelDetailPage_title__3-flL{
        font-size: 1.75rem;
        margin-top: 1rem;
    }
    .ModelDetailPage_subtitle__B5x0f{
        font-size: 1rem;
    }
    .ModelDetailPage_description__KDSQc{
        font-size: 1rem;
    }
}
.AboutPage_page_title__2bXeJ{
    font-size: 2.75rem;
    font-weight: 500;
    margin: 3.125rem 0 1.875rem;
}
.AboutPage_page_subtitle__3NHto{
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 0.625rem;
    opacity: 0.6;
}
.PageNotFound_page_title__3W7Do{
    font-size: 6rem;
    line-height: 6rem;
    font-weight: 500;
    margin-bottom: 1.875rem;
}
.PageNotFound_page_subtitle__30AFe{
    font-size: 1.875rem;
    line-height: 1.875rem;
    font-weight: 500;
    opacity: 0.6;
    margin-bottom: 0.75rem;
    padding: 0 1rem;
}
.PageNotFound_note__329Vt{
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.4;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
}
.PageNotFound_btn__2v9_B{
    font-size: 1.125rem;
    font-weight: 500;
    border: 1.5px solid #C4C4C4;
    border-radius: 0.375rem;
    padding: 0.375rem 1.125rem;
}
.PageNotFound_btn__2v9_B:hover{
    cursor: pointer;
    color: #CB9F86;
    border: 1.5px solid #CB9F86;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
}
.Button_btn__mPgD0{
    position: relative;
    display: inline-block;
    padding-left: 4.5rem;
    transition: all 0.5s ease;
}
.Button_btn_icon__3-eHa{
    fill: #484848;
    width: 3.5rem;
    position: absolute;
    left: 0;
    top: -0.75rem;
    transform: rotate(23deg);
    transition: all 0.5s ease;
}
.Button_btn_text__1xgcB{
    font-size: 1.5rem;
    font-weight: 600;
}
.Button_btn__mPgD0:hover{
    cursor: pointer;
    color: #CB9F86;
}
.Button_btn__mPgD0:hover .Button_btn_icon__3-eHa{
    fill: #CB9F86;
    transform: rotate(40deg);
}
