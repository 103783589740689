/* Animation */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

/* Main */
.block_wrapper{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    padding: 3.5rem 3.5rem 0 8rem;
    box-sizing: border-box;
    z-index: 1;
}
.block_wrapper a{
    text-decoration: none;
    color: #484848;
}
.page_title{
    font-size: 3rem;
    font-weight: 500;
    position: relative;
    min-width: 50%;
    text-align: left;
    margin-top: 2rem;
}
.page_subtitle{
    font-size: 2.5rem;
    position: relative;
    min-width: 50%;
    text-align: left;
    margin-left: 9rem;
    margin-top: 0.5rem;
}

/* Logo */
.block_wrapper_logo{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    z-index: 1;
}
.logo_name{
    position: relative;
    font-size: 6rem;
    margin-top: calc((100vh - 10.625rem)/2);
    animation: fadeIn 1s ease-in;
}
.logo_sparkle{
    fill: #CB9F86;
    position: absolute;
    top: -4rem;
    animation: fadeIn 1s ease-in-out infinite alternate;
}
.arrow01{
    position: absolute;
    transform: rotate(115deg);
    left: 40vw;
    bottom: 1rem;
    stroke: #484848;;
    stroke-width: 4px;
    animation: fadeIn 1s ease-in;
}

/* Web */
.underline{
    stroke: #CB9F86;
    stroke-width: 3px;
    position: absolute;
    width: 12rem;
    left: -1rem;
    top: 3.2rem;
}
.web_content_wrapper{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: -10rem;
}
.web_content{}
.web_description{
    font-size: 1.2rem;
    width: 35rem;
    text-align: left;
    margin-top: 10rem;
    margin-bottom: 4rem;
}
.web_description_title{
    font-size: 1.5rem;
    font-weight: 500;
    color: #CB9F86;
    margin-top: 1.5rem;
}
.web_btn{}
.icon_group{
    display: flex;
    flex-wrap: wrap;
    max-width: 40vw;
    opacity: 0.4;
}
.icon{
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.8rem;
}
.web_box_wrapper{
    position: relative;
    min-width: 31rem;
    min-height: 21rem;
    margin-top: 2rem;
}
.web_box_back{
    position: absolute;
    width: 26rem;
    height: 18rem;
    border-radius: 10px;
    border: 2px solid #C4C4C4;
    background-color: #F4F4F2;
    left: 5rem;
    bottom: 3rem;
    z-index: 9;
}
.web_box_front{
    position: absolute;
    width: 26rem;
    height: 18rem;
    border-radius: 10px;
    border: 2px solid #C4C4C4;
    background-color: #F4F4F2;
    left: -3rem;
    bottom: 0;
    z-index: 10;
}
.web_box_back:hover{
    z-index: 99;
}
.web_img_box{
    width: 95%;
    aspect-ratio: 16 / 9;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
}
.web_img{
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
}

/* App */
.app_content_wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.app_content{}
.icon_group_app{
    display: flex;
    flex-wrap: wrap;
    max-width: 20rem;
    opacity: 0.4;
}
.app_box{
    position: relative;
    width: 14rem;
    height: 29.3rem;
    transform: scale(1.2);
    margin: 0 3rem;
}
.app_img_box{
    position: relative;
    width: 14rem;
    height: 29.3rem;
    border: 3px solid #C4C4C4;
    border-radius: 40px;
    overflow: hidden;
}
.app_img_back{
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
    display: none;
}
.app_img_front{
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
    display: block;
}
.app_mask{
    position: absolute;
    width: 14rem;
    height: auto;
    stroke: #484848;
    top: 0;
    left: 0;
}
.app_box:hover .app_img_front{
    display: none;
}
.app_box:hover .app_img_back{
    display: block;
}
.app_description{
    font-size: 1.2rem;
    max-width: 25rem;
    padding: 0 1rem;
    margin-bottom: 5rem;
}
.app_btn{}

/* Design */
.design_content_wrapper{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5rem;
    margin-top: 3rem;
}
.design_content{}
.design_box_wrapper{
    position: relative;
    display: flex;
    width: 22rem;
    flex-direction: column;
    align-items: center;
}
.design_img_box{
    position: absolute;
    width: 20rem;
    height: 20rem;
    top: 1rem;
}
.design_img{
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
    display: none;
}
.design_box{
    width: 22rem;
    height: 22rem;
    stroke: #484848;
    fill: #E5E5E5;
    z-index: 10;
}
.design_box_tag{
    width: 7rem;
    height: 1.8rem;
    line-height: 1.8rem;
    color: #fff;
    background-color: #484848;
    border-radius: 5px;
}
.design_select{
    fill: #484848;
    stroke: #fff;
    transform: rotate(-35deg);
    position: absolute;
    width: 3.5rem;
    top: 12rem;
    right: -3rem;
    transition: all 1s ease;
    z-index: 11;
}
.design_box_wrapper:hover .design_img{
    display: block;
    animation: fadeIn 0.5s ease-in-out;
}
.design_box_wrapper:hover .design_box{
    fill: none;
}
.design_box_wrapper:hover .design_select{
    right: 3rem;
}
.icon_group_design{
    display: flex;
    flex-wrap: wrap;
    max-width: 40vw;
    opacity: 0.4;
}
.design_pen_wrapper{
    position: relative;
    margin-top: 4rem;
    margin-bottom: 6rem;
}
.design_pen_path{
    stroke: #CB9F86;
    stroke-width: 3px;
}
.design_pen{
    stroke: #484848;
    fill: #484848;
    position: absolute;
    top: -5px;
}
.design_unit_wrapper{}
.design_unit{
    font-size: 2rem;
    text-align: right;
}
.design_btn{}

/* Others */
.others_content_wrapper{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
}
.others_content{}
.icon_group_others{
    display: flex;
    flex-wrap: wrap;
    max-width: 40vw;
    opacity: 0.4;
}
.axis{
    fill: #CB9F86;
    width: 8rem;
    margin-top: -4rem;
    margin-right: 4rem;
}
.model_wrapper{
    width: 48rem;
    height: 24rem;
    border: 2px solid #C4C4C4;
    border-radius: 15px;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.others_btn{}

/* Conclusion */
.conclusion_content_wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5rem;
}
.conclusion_content{
    width: 25rem;
}
.category_wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    opacity: 0.4;
}
.category{
    margin: 1rem;
}
.category_icon{
    width: 5rem;
    height: 5rem;
}
.arrow_double{
    stroke: #CB9F86;
    stroke-width: 5px;
    position: relative;
    width: 10rem;
    margin: 0 2rem;
    transition: all 1s ease;
}
.arrow_double:hover{
    transform: rotate(360deg);
}
.conclusion_icon_box{
    position: relative;
    margin-bottom: 3rem;
}
.conclusion_icon{
    width: 4rem;
    height: 4rem;
}

/* Dot */
.dot_wrapper{
    position: fixed;
    left: 2.5rem;
    top: calc(100vh / 2 - 9.625rem);
    z-index: 999;
}
.dot_line{
    position: absolute;
    width: 1px;
    height: calc(100% - (12px + 1.5rem - 1.5px) * 2);
    background-color: #484848;
    top: calc(12px + 1.5rem - 1.5px);
    left: calc(12px / 2 - 0.5px);
    z-index: 1;
}
.dot_box{
    display: flex;
    align-items: center;
}
.dot{
    width: 12px;
    height: 12px;
    border: 1.5px solid #484848;
    margin: 1.5rem 0;
    transform: rotate(45deg);
    transition: all 0.5s ease;
    z-index: 999;
}
.dot:hover{
    cursor: pointer;
    border: 4px solid #CB9F86;
}
.dot:hover + .dot_name{
    display: block;
}
.dot_name{
    display: none;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1.2rem;
}

/* Mobile */
@media (max-width: 600px) {
    /* Main */
    .block_wrapper{
        padding: 3.5rem 1rem 0 1rem;
    }
    .page_title{
        font-size: 2rem;
    }
    .page_subtitle{
        font-size: 1.5rem;
        margin-left: 4rem;
        margin-top: 0.3rem;
    }
    /* Logo */
    .logo_name{
        font-size: 2.8rem;
    }
    .logo_sparkle{
        width: 2.2rem;
    }
    .arrow01{
        width: 10rem;
        left: calc(50vw - 5rem);
    }
    /* Web */
    .underline{
        width: 7rem;
        top: 1.5rem;
    }
    .web_content_wrapper{
        flex-direction: column;
        margin-top: 0;
    }
    .web_content{
        width: 100%;
    }
    .web_description{
        position: relative;
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .icon_group{
        position: relative;
        max-width: 100%;
        margin-top: 2.5rem;
        justify-content: center;
    }
    .web_box_wrapper{
        position: relative;
        min-width: 23rem;
        min-height: 15rem;
        max-width: 23rem;
        margin-left: auto;
        margin-right: auto;
    }
    .web_box_back{
        width: 18rem;
        height: 12rem;
    }
    .web_box_front{
        width: 18rem;
        height: 12rem;
        left: 0;
    }
    /* App */
    .app_content_wrapper{
        flex-direction: column;
        margin-top: 1.5rem;
    }
    .icon_group_app{
        position: relative;
        max-width: 100%;
        justify-content: center;
    }
    .app_box{
        transform: scale(0.8);
    }
    .app_description{
        margin-bottom: 3rem;
    }
    /* Design */
    .design_content_wrapper{
        flex-direction: column;
        margin-top: 2rem;
    }
    .design_box_wrapper{
        transform: scale(0.8);
    }
    .icon_group_design{
        position: relative;
        max-width: 100%;
        justify-content: center;
    }
    .design_pen_wrapper{
        transform: scale(0.6);
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .design_unit_wrapper{
        display: none;
    }
    /* Others */
    .others_content_wrapper{
       flex-direction: column;
       align-items: center;
    }
    .axis{
        display: none;
    }
    .model_wrapper{
        position: relative;
        width: 100%;
        margin-bottom: 3rem;
    }
    /* Conclusion */
    .conclusion_content_wrapper{
        flex-direction: column;
        margin-top: 2rem;
    }
    .conclusion_content{
        position: relative;
        width: 100%;
    }
    .category_wrapper{
        padding: 0 2rem;
    }
    .category_icon{
        width: 3rem;
        height: 3rem;
    }
    .arrow_double{
        margin: 3rem 0;
    }
    /* Dot */
    .dot_wrapper{
        display: none;
    }
}