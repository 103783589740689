.page_title{
    font-size: 6rem;
    line-height: 6rem;
    font-weight: 500;
    margin-bottom: 1.875rem;
}
.page_subtitle{
    font-size: 1.875rem;
    line-height: 1.875rem;
    font-weight: 500;
    opacity: 0.6;
    margin-bottom: 0.75rem;
    padding: 0 1rem;
}
.note{
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.4;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
}
.btn{
    font-size: 1.125rem;
    font-weight: 500;
    border: 1.5px solid #C4C4C4;
    border-radius: 0.375rem;
    padding: 0.375rem 1.125rem;
}
.btn:hover{
    cursor: pointer;
    color: #CB9F86;
    border: 1.5px solid #CB9F86;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
}