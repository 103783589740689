.page_title{
    font-size: 2.75rem;
    font-weight: 600;
    margin: 3.125rem 0 1.875rem;
}
.featured_section{
    width: 100%;
    overflow-x: auto;
}
.featured_section::-webkit-scrollbar {
    background: transparent;
    height: 0px;
}
.featured_wrapper{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 2rem 0 2.5rem;
}
.featured_title{
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: #CB9F86;
}
.featured_title .divider{
    width: 10rem;
    height: 1.5px;
    background-color: #CB9F86;
    margin: 0 1.5rem;
}
.section_divider{
    width: 24rem;
    height: 2px;
    background-image: linear-gradient(to right, #CB9F86 45%, rgba( 72, 72, 72, 0) 0%);
    background-position: bottom;
    background-size: 2rem 2px;
    background-repeat: repeat-x;
    margin: 2rem 0;
}
.category_wrapper{
    width: 54vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}
.category{
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.category_btn{
    width: 16vw;
    height: 16vw;
    padding: 5vw;
    box-sizing: border-box;
    border: 1.5px solid #C4C4C4;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.category_btn:hover{
    border: 1.5px solid #CB9F86;
    cursor: pointer;
}
.category_title{
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 1rem;
}
.null_text{
    font-size: 1.25rem;
    opacity: 0.6;
    margin: 1rem 0;
}

/* Mobile */
@media (max-width: 600px) {
    .featured_title .divider{
        width: 3rem;
    }
    .featured_wrapper{
        width: calc((60vw + 1.25rem) * 3);
        padding: 0 1rem;
    }
    .section_divider{
        width: 16rem;
    }
    .category_wrapper{
        width: 85vw;
    }
    .category_btn{
        width: 25vw;
        height: 25vw;
        padding: 6vw;
    }
    .category_title{
        font-size: 1rem;
        font-weight: 500;
        margin-top: 0.875rem;
    }
}