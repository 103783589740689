.container{
    min-height: 50vh;
    padding: 2.5rem 0 0.625rem;
    box-sizing: border-box;
}
.list_wrapper a{
    text-decoration: none;
    color: #484848;
}
.list_wrapper{
    display: flex;
    width: 60vw;
    flex-wrap: wrap;
    justify-content: space-between;
}
.item_box{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 48%;
    aspect-ratio: 4 / 3;
    cursor: pointer;
    margin-bottom: 2.25rem;
    border: 1.5px solid #C4C4C4;
    padding: 1rem 0 1.25rem;
    box-sizing: border-box;
    border-radius: 0.375rem;
}
.item_box:hover{
    border: 1.5px solid #CB9F86;
    color: #CB9F86;
    transform: scale(1.05, 1.05);
    transition: all 0.5s;
    background-color: #fff;
}
.item_img_box{
    width: 90%;
    aspect-ratio: 16 / 9;
}
.item_img{
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
}
.item_title{
    font-size: 1.5rem;
    margin-top: 1rem;
}
.item_subtitle{
    font-size: 1rem;
    display: none;
}
.item_box.featured{
    width: 20rem;
    margin: 0.625rem 1rem;
}
.item_box:hover .item_img_box{
    display: none;
}
.item_box:hover .item_subtitle{
    display: block;
    font-size: 1.2rem;
    margin-top: 1rem;
    padding: 0 1rem;
}

/* Mobile */
@media (max-width: 600px) {
    .list_wrapper{
        width: 85vw;
    }
    .item_box.featured{
        width: 60vw;
        margin: 0.625rem;
    }
    .item_title{
        font-size: 1rem;
    }
    .item_subtitle{
        font-size: 0.8rem;
    }
}