.btn{
    position: relative;
    display: inline-block;
    padding-left: 4.5rem;
    transition: all 0.5s ease;
}
.btn_icon{
    fill: #484848;
    width: 3.5rem;
    position: absolute;
    left: 0;
    top: -0.75rem;
    transform: rotate(23deg);
    transition: all 0.5s ease;
}
.btn_text{
    font-size: 1.5rem;
    font-weight: 600;
}
.btn:hover{
    cursor: pointer;
    color: #CB9F86;
}
.btn:hover .btn_icon{
    fill: #CB9F86;
    transform: rotate(40deg);
}