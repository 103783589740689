.page_title{
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1.875rem;
}
.content_wrapper{
    display: flex;
    min-height: calc(100vh - 16.875rem);
    margin: 2.5rem 0 3.125rem;
}
.model_wrapper{
    width: 50vw;
    height: 50vh;
}
.model_note{
    margin-top: 1rem;
}
.info_wrapper{
    width: 40vw;
    text-align: left;
    margin-left: 3vw;
}
.info_wrapper a{
    text-decoration: none;
}
.title{
    font-size: 2rem;
    font-weight: 500;
}
.subtitle{
    font-size: 1.25rem;
    color: #969696;
    margin: 0.375rem 0 1.5rem;
}
.description{
    font-size: 1.25rem;
}
.tools_wrapper{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.tools_box{
    padding: 0.375rem 0.625rem;
    border-radius: 0.375rem;
    border: 1px solid #C4C4C4;
    margin: 0.375rem 0.625rem 0.375rem 0;
    font-size: 0.875rem;
}
.note_wrapper{
    color: #969696;
    font-size: 1rem;
    margin-top: 1.25rem;
}
.note_title{
    font-weight: 500;
}

/* Mobile */
@media (max-width: 600px) {
    .page_title{
        font-size: 1.5rem;
    }
    .content_wrapper{
        flex-direction: column;
        margin: 1rem 0 2rem;
    }
    .model_wrapper{
        width: calc(100vw - 2rem);
        height: calc(100vw - 2rem);
        margin-bottom: 2rem;
    }
    .model_note{
        margin-top: 0.5rem;
    }
    .info_wrapper{
        width: calc(100vw - 2rem);
        margin-left: 0;
    }
    .title{
        font-size: 1.75rem;
        margin-top: 1rem;
    }
    .subtitle{
        font-size: 1rem;
    }
    .description{
        font-size: 1rem;
    }
}